.row-disabled {
    color: rgb(176, 176, 176) !important;
    font-weight: 400 !important;
    text-decoration: line-through !important;
}
.row-disabled span{
    color: rgb(176, 176, 176);
}
.row-disabled .ant-switch-inner{
    color: #000;
}

.ant-table-row-expand-icon {
    background: #1677ff !important;
    height: 35px !important;
    width: 35px !important;
    border-radius: 6px !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
}
.ant-table-row-expand-icon::before{
    color: #fff !important;
    top: 15px !important;
    inset-inline-end: 4px !important;
    inset-inline-start: 4px !important;
    height: 3px !important;
}
.ant-table-row-expand-icon::after{
    color: #fff !important;
    top: 4px !important;
    bottom: 4px !important;
    inset-inline-start: 14px !important;
    width: 3px !important;
}